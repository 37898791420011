import React from 'react';
import '../style/style.css';

import { Link } from 'react-router-dom';
import Marquee from "react-fast-marquee";
import {useUser} from "../Main";
import NewPost, {PostType} from '../components/NewPost';
import Post, { IPost } from '../components/Post';
import bootlickerImg from '../img/icons/DS-bootlicker.png';
import worldImg from '../img/icons/DS-world.png';
import chatroomImg from '../img/icons/DS-chatroom-enter.png'
import blackcat from '../img/blackcat.gif'
import Neko from '../components/Neko';
// import { ValentinesHeader } from '../components/special_headers/ValentinesHearts';
// import { TransDayHeader } from '../components/special_headers/Trans';
// import LunarNYHeader from '../components/special_headers/LNY2024/LunarNY2024';
// import { HalloweenHeader } from '../components/special_headers/Halloween';
// import { NYE } from '../components/special_headers/NewYearsCountdown';
// import TwitchStream from '../components/Twitch';
// import ReactTwitchEmbedVideo from "react-twitch-embed-video"
// import { PrideMonthHeader } from '../components/special_headers/Pride24';
// import { KosovoHeader } from '../components/special_headers/Kosovo';
interface FeedGlobals{
    username: string;
}



const checkUpdates = async () => {
    const curr = window.localStorage.getItem("VERSION");
    const res = await fetch(`/version`);
    const data = await res.json();
    console.log(`curr: ${curr}, response: ${data.version}`);
    console.log(` 
     ___     ____  __ __  ____  ___         
    |   \\   /    ||  |  ||    ||   \\        
    |    \\ |  o  ||  |  | |  | |    \\       
    |  D  ||     ||  |  | |  | |  D  |      
    |     ||  _  ||  :  | |  | |     |      
    |     ||  |  | \\   /  |  | |     |      
    |_____||__|__|  \\_/  |____||_____|      
                                            
     _____  ___     __  ____   ____  _     
    / ___/ /   \\   /  ]|    | /    || |    
    (   \\_|     | /  /  |  | |  o  || |    
    \\__  ||  O  |/  /   |  | |     || |___ 
    /  \\ ||     /   \\_  |  | |  _  ||     |
    \\    ||     \\     | |  | |  |  ||     |
    \\___| \\___/ \\____||____||__|__||_____|
                                                                        
            version: ${curr}
        `)
    if(curr == data.version) return;
    alert("New version of David Social found! Updating to latest version...")
    window.localStorage.setItem("VERSION", data.version);
    window.location.reload();
}


export default function Feed() {
    const {username} = useUser();
    const [loaded, setLoaded] = React.useState(false);
    const [postData, setPostData] = React.useState<any[]>([]);
    const [tickerText, setTickerText] = React.useState<string>("");
    const [globalView, setGlobalView] = React.useState<boolean>(false);
    const [fetchingMoreFeed, setFetchingMoreFeed] = React.useState<boolean>(false);
    const indexWindow = React.useRef<number>(42)

    // let postData : Object[] = [];

    const scrollToLastFocusedPost = (attempts : number, target? : string)=>{
        if(attempts > 60) return; // element prob no longer exists
        console.log(`wanna scroll to id: ${target}`)
        const targetId = target ? target : sessionStorage.getItem("lastPostTarget");
        // const targetId : string | null = lastFocusedPost;
        console.log(`looking for: ${targetId}`)
        if (targetId === null || targetId.length === 0) return;
        
        const targetEl = document.getElementById(targetId);
        if(!targetEl){
            // target hasn't loaded yet
            setTimeout(()=>scrollToLastFocusedPost(attempts+1, targetId), 100);
            return;
        }
        // scroll into view
        targetEl?.scrollIntoView({behavior:'smooth', block:"center"})
    }
    
    const parseSessionStorage = (val : string | null) : boolean => {
        if(val === null || val === undefined || val === "false") return false;
        return true;
    }

    React.useEffect(()=> {
        if(!username) return; 
        checkUpdates();

        

        fetch(`/get-ticker-text`)
        .then(res=>res.json())
        .then(data=>{
            setTickerText(data.tickerText)
        })

        const lastViewGlobal = (parseSessionStorage(sessionStorage.getItem("globalFeed")));
        loadFeed(lastViewGlobal);
        // const getString =  lastViewGlobal ? `/global-feed?window=${indexWindow.current}` : `/bootlicker-feed?id=${username}&window=${indexWindow.current}`
        // setGlobalView(lastViewGlobal);

        // fetch(getString)
        // .then(res => res.json())
        // .then(data => {
        //     setPostData(data);
        //     setLoaded(true);
        //     scrollToLastFocusedPost(0);
        // })

    }, [username])

    const toggleFeedType = () =>{
        const newView = !globalView;
        indexWindow.current = 3;
        sessionStorage.setItem("lastPostTarget", "");
        sessionStorage.setItem("globalFeed", newView.toString());
        setGlobalView(newView);
        setLoaded(false);
        loadFeed(newView);
    }

    const loadFeed = (isGlobalView : boolean) => {
        if(isGlobalView){ // global view
            setGlobalView(true);
            loadGlobalFeed();
        }
        else{
            setGlobalView(false);
            loadBootlickerFeed();
        }
    }

    const loadBootlickerFeed = () =>{
        fetch(`/bootlicker-feed?id=${username}&window=${indexWindow.current}`)
            .then(res => res.json())
            .then(data => {
                setPostData(data);
                setLoaded(true);
                setFetchingMoreFeed(false);
                scrollToLastFocusedPost(0);
            })
    }

    const loadGlobalFeed = () => {
        // !!!
        fetch(`/global-feed?window=${indexWindow.current}`)
            .then(res => res.json())
            .then(data => {
                setPostData(data);
                setLoaded(true);
                setFetchingMoreFeed(false);
                scrollToLastFocusedPost(0);
            })
    }

    const loadMore = () => {
        setFetchingMoreFeed(true)
        sessionStorage.setItem("lastPostTarget", "");
        indexWindow.current = indexWindow.current + 50;
        loadFeed(globalView);
    }
    return(
        <div id="main-content">
            <div id="content-panel">
                <div className='mobile-pad'>
                {/* <div style={{textAlign:"center"}}>
                    <h1>Welcome to Twitter (JK!!! APRIL FOOLS XD)</h1>
                    <audio controls src="https://bigsoundbank.com/UPLOAD/mp3/1832.mp3">
                    </audio>
                </div> */}
                
                    <div style={{textAlign:"center"}}>
                        <div style={{textAlign:"left", display:"inline-block", width:"50%"}}>
                            <div style={{display:"inline-block"}}>
                                <div style={{textAlign:"center"}}>feed mode</div>
                                <img onClick={toggleFeedType} src={bootlickerImg} style={globalView ? {opacity:0.3} : {}} className='selectable-icon' />
                                <img onClick={toggleFeedType} src={worldImg} style={globalView ? {} : {opacity:0.3}}      className='selectable-icon' />
                            </div>

                        </div>
                        <div style={{textAlign:"right", display:"inline-block", width:"50%"}}>
                            <Link to="/~/chat"><img src={chatroomImg} style={{width:"40px", height:"40px"}}className='selectable-icon'/></Link>
                        </div>
                    </div>
                <h1>
                    {globalView?
                    `What's happening ${!username? "loading" : username} and the whole world!`
                    : `What's happening ${!username? "loading" : username} and Bootlickers! `}
                {/* Session ID: {document.cookie.split(';')[0].split('=')[1]} */}
                </h1>
                <NewPost type={PostType.Init}/>
                
                {/* <div style={{textAlign:"center"}}>
                <h2>🎆Special Event: GAMER STREAM 2🎮</h2>
                Begins at 2:30PM ET / 1:30PM CT / 11:30AM PT

                </div>

                <ReactTwitchEmbedVideo channel="bugvevo" width="100%" /> */}

                {/* !!! SPECIAL EVENT HEADERS HERE !!! */}
                {/* <TransDayHeader/> */}
                {/* <div className="holiday-text" >
                <Marquee speed={30} gradient={false} ><b>The David Secret Santa Event is now active <Link to="/~/thread/22554" className='holiday-link'>click for event details</Link></b></Marquee>
                </div> */}
                <div style={{backgroundColor:"magenta"}}>
                    <div style={{textAlign:"center", transform:"scale(1.3,5)", pointerEvents:"none", fontFamily:"Comic Sans MS, Comic Sans, cursive", color:"limegreen"}}>
                        <b>Game Breaker'v Toolkit Dvam #ff showcase</b>
                    </div>
                        The Gamebreakers toolkit is a semi-annual game jam where game makers must make the worst game possible.
                        <p/>
                        Here we showcase the best worst games of of the competition!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                        <p/>
                        <div style={{border:"1px solid black", backgroundColor:"#fAB"}}>
                            <h3>
                            <b>♖🂶🂩♝ 𝔊𝔞𝔪𝔦𝔫𝔤 Ⅱ ♞🃛🃌☠</b> by <Link to="/~/u/itv1234578">itv1234578</Link> (on David Social!)

                            </h3>
                            <img src="https://img.itch.zone/aW1nLzE3NjkxMjcwLmpwZw==/315x250%23c/qPEfIn.jpg"></img>
                            <a href="https://itch.io/jam/game-breakers-toolkit-jam-11/rate/2921694" target="_blank" rel="noopener noreferrer" style={{fontWeight:"bold", color:"blue"}}>Play on Twitch page</a>
                        </div>

                        <div style={{border:"1px solid black", backgroundColor:"#fAB"}}>
                            <h3>
                            <b>Can't Stop Danc3ng</b> by <a href="https://sapazu.itch.io/" target="_blank">Sapazu</a>

                            </h3>
                            <img src="https://img.itch.zone/aW1nLzE3NjUzNTU3LmdpZg==/315x250%23c/Y%2BIi9f.gif" style={{maxWidth:"59%"}}></img>
                            <a href="https://itch.io/jam/game-breakers-toolkit-jam-11/rate/2949407" target="_blank" rel="noopener noreferrer" style={{fontWeight:"bold", color:"blue"}}>Play on Twitch page</a>
                        </div>
                        <div style={{border:"1px solid black", backgroundColor:"#fAB"}}>
                            <h3>
                            <b>Babynator AND Kazooie I : Mom Says I'm Built Different</b> by <a href="https://manana1312.itch.io/" target="_blank">Call Me Mañana</a>

                            </h3>
                            <img src="https://img.itch.zone/aW1nLzE3NTI1OTQ4LnBuZw==/315x250%23c/feAqIZ.png"></img>
                            <a href="https://itch.io/jam/game-breakers-toolkit-jam-11/rate/2925074" target="_blank" rel="noopener noreferrer" style={{fontWeight:"bold", color:"blue"}}>Play on Twitch page</a>
                        </div>
                    <div style={{backgroundColor:"#66f", textAlign:"center"}}>
                        <a href="https://itch.io/jam/game-breakers-toolkit-jam-11/results" target="_blank" rel="noopener noreferrer">see the rest here :)</a>
                    </div>

                </div>
                <br/>
                
                    
                    {/* <Marquee speed={50} gradient={false} >please socialize responsibly</Marquee> */}
                        <Marquee speed={50} gradient={false} >
                            <span dangerouslySetInnerHTML={{__html: tickerText}}/>
                        </Marquee>
     

                
                <Link to='/~/public-update-ticker'>update ticker or submit cat petting pics</Link>
                <p>
                </p>
                

                <div style={{textAlign:"right"}}>
                    <Neko/>
                </div>
                {/* <hr style={{borderTop: "1.5px dotted black", borderBottom:"0px none black"}}/> */}
                </div>
                <div id="postarea">
                    {
                        loaded? 
                        <div>
                            {postData.map((v) => <Post avi={v.avi} 
                                                       username={v.username} 
                                                       content={v.content} 
                                                       attachImage={v.attached_image} 
                                                       likes={v.likes} 
                                                       timestamp={v.timestamp}
                                                       id={v.id}
                                                       key={v.id}
                                                       ncomments={v.ncomments}
                                                       reply_to={v.reply_to}
                                                       david_selection={v.david_selection}
                                                       mask={v.mask}
                                                       mask_desc={v.mask_desc} 
                                                       />)}
                            <button onClick={loadMore} disabled={fetchingMoreFeed}>{fetchingMoreFeed ? "ok loading!!!!!" : "load more"}</button>

                        </div>
                        :
                        <img src="/img/loading-hourglass.gif"/>
                    }
                    {
                        ((postData.length <= 2) && loaded )?
                        <h3>
                            Welcome to David Social!<br/>
                            You're not following anyone 😔 Contact <Link to='/~/u/David'>David</Link> for suggestions on who to follow <br/>
                            Click News for more information about how to use the site, as well as how to install it as an app on your phone<br/>
                            </h3>

                        :
                        <></>
                    }
                    

                </div>
            </div>
        </div>
    )
}

